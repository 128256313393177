import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/apply/shop',
        name: 'ApplyShop',
        component: () => import('@/views/apply/Shop')
    },
    {
        path: '/apply/success',
        name: 'ApplySuccess',
        component: () => import('@/views/apply/Success')
    },
    {
        path: '/agreement',
        name: 'Agreement',
        component: () => import('@/views/Agreement')
    }
];

const router = new VueRouter({
    mode: 'history',
    routes
});

export default router;
