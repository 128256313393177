import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import '@/assets/styles/reset.css';
import VueMeta from 'vue-meta';

// import 'amfe-flexible'

Vue.config.productionTip = false;

Vue.filter('imagePrefix', value => {
    return `${process.env.VUE_APP_IMAGE_HOST}${value}`;
});

Vue.use(VueMeta, {
    refreshOnceOnNavigation: true
});

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app');
