<template>
    <div id="app">
        <router-view />
    </div>
</template>
<script>
export default {
    name: 'H5Container',
    mounted() {
        this.setRootFontSize();
        window.addEventListener('resize', this.setRootFontSize);
    },
    destroyed() {
        window.removeEventListener('resize', this.setRootFontSize);
    },
    methods: {
        setRootFontSize() {
            document.documentElement.style.fontSize = '10px';
            let width = document.documentElement.clientWidth;
            let rootFontSize;
            if (width <= 750) {
                rootFontSize = width / 7.5;
            } else {
                rootFontSize = 750 / 7.5;
            }
            document.documentElement.style.fontSize = `${rootFontSize}px`;
        }
    }
};
</script>

<style lang="less">
* {
    box-sizing: border-box;
}
.van-toast {
    width: 172px !important;
    min-height: 172px !important;
    font-size: 28px !important;
    line-height: 40px !important;
}
</style>
